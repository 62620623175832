
.featureinfobox {
    display: flex;
   flex-direction: column; 
}

.featureinfobox-title {
    font-size: xx-large;    
}
.featureinfobox-icons img{
    max-height: 1.5em;
    width: 1.5em;
}
.featureinfobox-icons {
}

.featureinfobox-icons span {
    display: inline-block;
    padding-right: 0.1em;
}

.featureinfobox-titlebar{
    font-size: large;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid var(--foreground-color);
}

.featureinfobox-content {
    display: block;
    max-height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 1em;
}
@media only screen and (max-width: 600px), only screen and (max-height: 600px) {
    .featureinfobox-content {
        display:block;
        max-height: unset !important;
        overflow-y: auto;
    }
}

@media only screen and (max-height: 600px) and (min-width: 600px){
    /* landscape mode: the first tagrendering of the infobox gets a special treatment and is placed on the right*/
    .featureinfobox-content {
        position: relative;
        width: 100% !important;
        max-width: unset !important;
        max-height: unset !important;
        height: 100vh;
    }
    
    .answer {
        max-width: 48% !important;
        padding-right: 0.3em;
        box-sizing: border-box;
    }

    .question {
        max-width: 48% !important;
        padding-right: 0.3em;
        box-sizing: border-box;
    }
    
    .first-rendering{
        position: absolute;
        left: 50%;
        width: 94%;
        padding-right: 0.3em;
        box-sizing: border-box;
    }
}


.answer {
    display: flex;
    width: 100%;
    font-size: large;
    justify-content: space-between;
    word-break: break-word;
}

.question .form-text-field > input {
    width: 100%;
    box-sizing: border-box;
}

.question {
    display: block;
    margin-top: 1em;
    background-color: var(--subtle-detail-color);
    color: var(--subtle-detail-color-contrast);
    padding: 1em;
    border-radius: 1em;
    font-size: larger;

}

.question svg {
    width: 100%;
    height: 100%;
}

.question a {
    pointer-events: none;
    text-decoration: none;
    color: var(--subtle-detail-color-contrast)
}

.question-text {
    font-size: larger;
    font-weight: bold;
    margin-bottom: 0.5em;
}

.question-text img {
    max-width: 100%;
}

.question-subtext {
    font-size: medium;
    font-weight: normal;
}

.question-option-with-border {
    border: 2px solid lightgray;
    border-radius: 0.5em;
    display: inline-block;
    width: 100%;
    margin:0;
    margin-left: -2em;
    box-sizing: border-box;
    padding: 0.5em;
    padding-left: 2em;
}

input:checked + label .question-option-with-border {
    border: 2px solid var(--subtle-detail-color-contrast);
}


.save {
    display: inline-block;
    border: solid var(--catch-detail-color-contrast) 2px;
    background-color: var(--catch-detail-color);
    color: var(--catch-detail-color-contrast);
    padding: 0.2em 0.6em;
    font-size: x-large;
    font-weight: bold;
    border-radius: 1.5em;
}

.cancel {
    display: inline-block;
    border: solid black 0.5px;
    padding: 0.2em 0.3em;
    border-radius: 1.5em;
}

.login-button-friendly {
    display: inline-block;
    background-color:var(--catch-detail-color);
    color: var(--catch-detail-color-contrast);
    border: solid var(--catch-detail-color-contrast) 2px;
    padding: 0.2em 0.6em;
    font-size: large;
    font-weight: bold;
    border-radius: 1.5em;
    box-sizing: border-box;
    width: 100%;
}

.save-non-active {
    display: inline-block;
    border: solid lightgrey 2px;
    color: grey;
    padding: 0.2em 0.3em;
    font-size: x-large;
    font-weight: bold;
    border-radius: 1.5em;
}

.edit-button img {
    width: 1.3em;
    height: 1.3em;
    padding: 0.5em;
    border-radius: 0.65em;
    border: solid var(--popup-border) 1px;
    font-size: medium;
    float: right;
}

.edit-button svg {
    width: 1.3em;
    height: 1.3em;
    padding: 0.5em;
    border-radius: 0.65em;
    border: solid var(--foreground-color) 1px;
    stroke: var(--foreground-color) !important;
    fill: var(--foreground-color) !important;
    font-size: medium;
    float: right;
}

.edit-button svg path{
    stroke: var(--foreground-color) !important;
    fill: var(--foreground-color) !important;
}